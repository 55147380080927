import React from 'react';
import { Button, Icon } from 'rsuite';

export const CustomButton = ({
  type = 'primary',
  buttonColor,
  textColor,
  extraClasses,
  children,
  url,
  ...props
}) => {
  return (
    <Button
      appearance={type}
      size="lg"
      className={`${buttonColor} ${textColor} ${extraClasses} rounded-lg px-8`}
      href={url}
      {...props}
    >
      {children}
    </Button>
  );
};

export const SuccessButton = (props) => {
  return (
    <CustomButton color="green" {...props}>
      <Icon icon="download2"></Icon> Download
    </CustomButton>
  );
};
export const CancelButton = (props) => {
  return (
    <CustomButton type="default" {...props}>
      <Icon icon="times-circle"></Icon> Cancel
    </CustomButton>
  );
};
export const RetryButton = (props) => {
  return (
    <>
      <CustomButton type="default" extraClasses="text-red-600 text-lg bg-red-200" {...props}>
        <Icon icon="undo"></Icon> Retry
      </CustomButton>
    </>
  );
};

export default CustomButton;
