import { Box } from '@material-ui/core';
import { DynamicProgress } from 'components/feedbacks/Progress';
import { CancelButton, CustomButton, SuccessButton } from 'components/inputs/Button';
import CustomDropDown from 'components/inputs/CustomDropDown';
import DateTimePicker from 'components/inputs/DateTimePicker';
import Layout from 'components/Layout';
import { colSpan, ContainerBox, SubContainerBox, twStyles } from 'components/layouts/Container';
import moment from 'moment';
import React, { useEffect, useReducer, useState } from 'react';
import { Icon, IconButton, Popover, Whisper } from 'rsuite';
import { useGState } from 'state/store';
import { useAPI, useFetch } from 'utils/customHooks';
import { getNodesSelectOptions } from 'utils/node';
import reportReducer, {
  ASYNC_REPORT_API,
  REPORT_ACTION_TYPE,
  REPORT_DEFAULT,
  REPORT_SECTIONS,
} from '../asyncReports/reportReducer';

const permissionFilter = (permission) => (item) => {
  return item.permission ? permission.map((per) => per.code).includes(item.permission) : true;
};

export function ReportSection(props) {
  const {
    section: { sectionName, reportType, showNodeSelector, url, showDateRange = false, maxDays },
    branches,
    fetchedReports,
    queueReport,
    cancelReport,
    getReportName,
  } = props;

  const filteredReports = fetchedReports.filter((item) => item.reportType === reportType);

  const speaker = (
    <Popover className="text-orange-600 text-center">
      Same query is currently <br />
      under process please wait
    </Popover>
  );
  const [dateRange, setDateRange] = useState([]);
  const [selectedValues, setSelectValues] = useState([]);
  let trigger = React.useRef();

  const getDateRangeKey = (dateRange) => dateRange.map((date) => date.toString()).join(',');

  const getTimeStamp = (createdAt) => {
    const diff = new Date(Math.abs(new Date() - new Date(createdAt)));
    const h = diff.getUTCHours();
    const min = diff.getUTCMinutes();
    return h > 0 ? `${h} hours ago` : `${min} mins ago`;
  };

  const getButtonElement = ({ report, twclass }) => {
    return report.status === 'completed' ? (
      <SuccessButton twclass={twclass} url={ASYNC_REPORT_API.downloadReport(report.id)} />
    ) : report.status === 'cancelled' ? (
      // <RetryButton
      //   // onClick={(event) => reportId && onRetry(event, reportId)}
      //   twclass={twclass}
      // />
      <></>
    ) : (
      <CancelButton onClick={() => cancelReport(report.id)} twclass={twclass} />
    );
  };

  const onClickGenerateReport = (event, reportType) => {
    trigger.current.hide();
    event.stopPropagation();
    const uniqueId = getDateRangeKey([...selectedValues, ...dateRange]);
    if (filteredReports.findIndex((item) => item.progress < 100 && item.key === uniqueId) === -1) {
      const processingReportStates = {
        ...{
          key: uniqueId,
          branchIds: selectedValues,
          createdAt: new Date(),
          reportType: reportType,
        },
        ...REPORT_DEFAULT,
      };
      queueReport({
        report: processingReportStates,
        reportType: reportType,
        data: {
          branchIds: selectedValues,
        },
        dateRange: dateRange,
      });
    } else {
      trigger.current.show();
    }
  };

  const getDbReportsNode = (dbReports) => {
    return dbReports.flatMap((dbReport, index) => (
      <SubContainerBox key={++index} cols={5}>
        <IconButton key={++index} icon={<Icon icon="times-circle"></Icon>} twclass={colSpan.one} />
        <div key={++index} twclass={colSpan.four}>
          {getReportName(dbReport.reportType, dbReport.createdAt)}
        </div>
        <div key={++index} twclass={colSpan.two}>
          {getTimeStamp(dbReport.createdAt)}
        </div>
        <div
          key={++index}
          twclass={colSpan.two}
          className={dbReport.status === 'failed' ? 'text-red-600' : undefined}
        >
          {dbReport.status === 'failed' ? 'Failed' : dbReport.status}
        </div>
        {getButtonElement({
          report: dbReport,
          twclass: colSpan.three,
        })}
      </SubContainerBox>
    ));
  };
  const getActiveReportsNode = (reports) => {
    return reports.flatMap((report, index) => (
      <SubContainerBox key={++index} cols={2}>
        <DynamicProgress
          key={++index}
          progress={report.progress}
          createdAt={report.createdAt}
          canceled={report.canceled}
          fileName={getReportName(report.reportType, report.createdAt)}
          twclass="sm:w-9/12 md:w-9/12 lg:w-9/12 xl:w-10/12"
        />
        {getButtonElement({
          report: report,
          twclass: 'sm:w-3/12 md:w-3/12 lg:w-3/12 xl:w-2/12',
        })}
      </SubContainerBox>
    ));
  };

  const getCustomizationOptions = (
    showNodeSelector = true,
    showDateRange = false,
    url = undefined,
    maxDays
  ) => {
    const isButtonDisabled = showNodeSelector
      ? !selectedValues.length
      : showDateRange
      ? !(dateRange.length > 1)
      : true;

    return [
      <SubContainerBox key={reportType} cols={showDateRange ? 3 : 2}>
        {showNodeSelector && (
          <CustomDropDown
            branches={branches}
            onChange={(values) => setSelectValues(values)}
            twclass={colSpan.four}
          />
        )}
        {showDateRange && (
          <DateTimePicker
            onChange={(dateRange) => setDateRange(dateRange)}
            twclass={colSpan.four}
            maxDays={maxDays}
          />
        )}
        {url ? (
          <SuccessButton
            twclass="sm:w-3/12 md:w-3/12 lg:w-3/12 xl:w-2/12"
            url={isButtonDisabled ? '' : url}
            disabled={isButtonDisabled}
          />
        ) : (
          <Whisper
            placement="top"
            trigger="click"
            triggerRef={trigger}
            speaker={speaker}
            twclass={showNodeSelector ? colSpan.full : colSpan.four}
          >
            <CustomButton
              buttonColor="bg-gray-800"
              disabled={isButtonDisabled}
              onClick={(event) => onClickGenerateReport(event, reportType)}
              twclass={showNodeSelector ? colSpan.full : colSpan.four}
              extraClasses="px-10"
            >
              Generate
            </CustomButton>
          </Whisper>
        )}
      </SubContainerBox>,
    ];
  };
  const activeReports = filteredReports.filter(
    (rep) => rep.progress < 100 && (rep.status === 'progress' || rep.status === 'created')
  );
  const dbReports = filteredReports.filter(
    (rep) => rep.progress === 100 || rep.status === 'cancelled'
  );
  // console.log(activeReports, dbReports, 'act');

  return (
    <React.Fragment key={sectionName}>
      <div className={`${twStyles.container} text-lg mb-5 px-2`}>{sectionName}</div>
      <ContainerBox cols={1}>
        {getCustomizationOptions(showNodeSelector, showDateRange, url, maxDays)}
      </ContainerBox>
      {activeReports.length > 0 && (
        <ContainerBox cols={1}>{getActiveReportsNode(activeReports)}</ContainerBox>
      )}
      {dbReports.length > 0 && <ContainerBox cols={1}>{getDbReportsNode(dbReports)}</ContainerBox>}
    </React.Fragment>
  );
}

export default function Reports(props) {
  const { branches, user, nodes } = useGState((s) => ({
    date: s.date,
    user: s.user,
    branches: s.branches,
    nodes: s.nodes,
  }));

  const getReportName = (reportType, createdAt) => {
    return `${reportType} ${moment(createdAt).format('YYYY-MM-DD HH:mm ss')}.xlsx`;
  };

  const [fetch] = useAPI();
  const [fetchProgress] = useAPI();
  const [dbReports] = useFetch(`${ASYNC_REPORT_API.getLatest(10)}`);

  const [state, dispatch] = useReducer(reportReducer, []);

  const cancelReport = (reportId) => {
    fetch({
      method: 'post',
      url: ASYNC_REPORT_API.cancel(reportId),
    });
  };
  const queueReport = ({ report, reportType, data, dateRange }) => {
    fetch(
      {
        method: 'post',
        url: ASYNC_REPORT_API.generate({
          type: reportType,
          dateRange: dateRange,
        }),
        data: data,
      },
      (response) => {
        report.id = response.id;
        dispatch([REPORT_ACTION_TYPE.ADD, report]);
      }
    );
  };
  const getLatestReports = (hours = 1) => {
    return {
      method: 'get',
      url: ASYNC_REPORT_API.getLatest(hours),
    };
  };

  useEffect(() => {
    if (dbReports) {
      dispatch([REPORT_ACTION_TYPE.INIT, dbReports]);
    }
  }, [dbReports]);

  useEffect(() => {
    let didCancel = false;
    state.some((item) => item.progress < 100 && item.status !== 'cancelled') &&
      setTimeout(
        () =>
          fetchProgress(getLatestReports(1), (response) => {
            if (!didCancel) {
              dispatch([REPORT_ACTION_TYPE.UPDATE_PROGRESS, response]);
            }
          }),
        3000
      );
    return () => {
      didCancel = true;
    };
  }, [fetchProgress, state]);

  const selectorData = {
    nodes,
    branches,
    nodesTillFirstLevel: getNodesSelectOptions(nodes).map((n) => ({ name: n.label, id: n.value })), //this will be 0 and 1 depth level nodes, root notes and it's children
    leafNodes: branches.filter((b) => b.isLeaf),
  };
  const sectionProps = { user, state, queueReport, cancelReport, getReportName, selectorData };
  return (
    <Layout {...props} hideFilters={true}>
      <Box p={2}>
        <div className={`${twStyles.container} text-3xl mb-8 px-2`}>Report Management</div>
        <DifferentReportSections {...sectionProps} />
      </Box>
    </Layout>
  );
}

function DifferentReportSections(props) {
  const { user, state, queueReport, getReportName, selectorData, cancelReport } = props;

  const filteredReports = REPORT_SECTIONS.filter(
    (s) => s.clients == undefined || s.clients.includes(user.clientId)
  );
  const { permission } = useGState((s) => ({
    permission: s.permission,
  }));

  return filteredReports
    .filter(permissionFilter(permission))
    .map((section, index) => (
      <ReportSection
        key={++index}
        section={section}
        branches={
          section.selectorKey ? selectorData[section.selectorKey] : selectorData['nodes']
        }
        fetchedReports={state}
        queueReport={queueReport}
        cancelReport={cancelReport}
        getReportName={getReportName}
      />
    ));
}
