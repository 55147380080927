import React, { useState } from 'react';
import { CheckPicker, Checkbox, Button } from 'rsuite';

const footerStyles = {
  padding: '10px 2px',
  borderTop: '1px solid #e5e5e5',
};

const footerButtonStyle = {
  float: 'right',
  marginRight: 20,
  marginTop: 2,
};

const container = {
  marginBottom: 20,
};

export default function CustomDropDown(props) {
  const { branches, onChange } = props;
  let picker = React.useRef();
  const [state, setState] = useState({
    indeterminate: false,
    checkAll: false,
    value: [],
  });

  let branchList = branches.filter((item) => item.id !== -1);
  const allValue = branchList.map((item) => item.id);

  const handleChange = (value) => {
    setState({
      value: value,
      indeterminate: value.length > 0 && value.length < allValue.length,
      checkAll: value.length === allValue.length,
    });
    onChange(value);
  };

  const handleCheckAll = (value, checked) => {
    const nextValue = checked ? allValue : [];
    setState({
      value: nextValue,
      indeterminate: false,
      checkAll: checked,
    });
    onChange(nextValue);
  };
  const handleOnClose = (event) => {
    event.stopPropagation();
    // console.log(picker);
    picker.current.close();
  };
  return (
    <div>
      <CheckPicker
        size="lg"
        data={branchList}
        searchable
        labelKey="name"
        valueKey="id"
        placeholder="Select Branches"
        ref={picker}
        style={{ width: 224 }}
        value={state.value}
        onChange={(value) => handleChange(value)}
        className="max-w-full min-w-full"
        menuStyle={container}
        renderExtraFooter={() => (
          <div style={footerStyles}>
            <Checkbox
              className="m-0"
              inline
              indeterminate={state.indeterminate}
              checked={state.checkAll}
              onChange={(value, checked) => handleCheckAll(value, checked)}
            >
              Check all
            </Checkbox>

            <Button
              style={footerButtonStyle}
              appearance="primary"
              size="sm"
              onClick={handleOnClose}
            >
              Ok
            </Button>
          </div>
        )}
      />
    </div>
  );
}
